import React, { useContext, useEffect, useRef, useState } from 'react';
import { video_id_find } from '../../api/api';
import LoadingContext from '../../context/loadingContext';
import ToastContext from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { Button, ConfigProvider, Input, Select, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import VideoEdit from '../../components/videoManager/VideoEdit';
import TableFilter from '../../UI/TableFilter';
import VideoAdd from '../../components/videoManager/VideoAdd';
import useTableSize from '../../helper/useTableSize';

const VideoList = () => {

  const {setIsLoading} = useContext(LoadingContext)
  const {showToast ,isRefresh} = useContext(ToastContext)
  const location = useLocation()
  const [videoListData, setVideoListData] = useState([])
  const [showVideoAdd, setShowVideoAdd] = useState(false)
  const [showVideoEdit, setShowVideoEdit] = useState(false)
  const [editVideoData, setEditVideoData] = useState({})
  const [selectVideoState, setSelectVideoState] = useState("1")
  const tableScrollY = useTableSize(60, 55)

  const typeOptions = [{label: '未绑定广告' , value: '1'}, {label: "已绑定广告", value: "2"}, {label: "鬼手处理中", value: "3"}, {label: "鬼手处理失敗", value: "4"}]

  console.log(videoListData);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`搜寻 ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜寻
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重设
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            筛选
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            关闭
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if(record[dataIndex])
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      text
  });

  
  const editClickHandler = (record) => {
    setEditVideoData(record)
    setShowVideoEdit(true)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: '影片id',
      key: 'ID',
      width: 80,      
      ...getColumnSearchProps('影片id'),
      sorter: (a, b) => a.影片id - b.影片id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '用户名称',
      dataIndex: '用戶名稱',
      key: '用户名称',
      width: 200,      
      ...getColumnSearchProps('用戶名稱'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '标题 ',
      dataIndex: '影片標題',
      key: '标题',
      width: 300,      
      ...getColumnSearchProps('影片標題'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '影片连结',
      dataIndex: '影片網址',
      key: '影片连结',
      width: 200,      
      ...getColumnSearchProps('影片網址'),      
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    
    {
      title: '片头加广告',
      dataIndex: '片頭加廣告',
      key: '片头加广告',
      width: 100,      
      ...getColumnSearchProps('片頭加廣告'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '全程下方加广告',
      dataIndex: '全程下方加廣告',
      key: '全程下方加广告',
      width: 100,      
      ...getColumnSearchProps('全程下方加廣告'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '片尾加广告',
      dataIndex: '片尾加廣告',
      key: '片尾加广告',
      width: 100,      
      ...getColumnSearchProps('片尾加廣告'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    }, 
    {
      title: '错误讯息',
      dataIndex: 'text',
      key: '错误讯息',
      width: 120,      
      ...getColumnSearchProps('text'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '鬼手ID',
      dataIndex: 'process_id',
      key: '鬼手ID',
      width: 120,      
      ...getColumnSearchProps('process_id'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '其他詳細資訊',
      dataIndex: '3p_process_result',
      key: '其他详细资讯',
      width: 300,      
      ...getColumnSearchProps('3p_process_result'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text && Object.entries((text)).map(([key, value]) => <p>{`${key}: ${value}`}</p>)}
        </div>
      ),
      disabled: true
    },
    {
      title: '操作',
      dataIndex: 'active',
      key: '操作',
      width: 100,      
      fixed: 'right',            
      render: (text, record) => (
        <div className='flex space-x-2'>
          <ConfigProvider theme={{
            components: {
              Button: {
                linkHoverBg: "#CCCCCC",
                colorLinkHover: "#FFFFFF"    
              },
            },
          }}>           
            <Button type="link" className='flex justify-around bg-gray-400 text-white rounded-md' disabled={selectVideoState === "3"} onClick={() => editClickHandler(record)}>编辑</Button>                 
          </ConfigProvider>
        </div>
      ),
    },
    
  ];

  const defaultCheckedList = columns.filter(item => item.key !== '其他详细资讯').map((item) => item.key);

  const columnOptions = columns.map(item => ({label: item.title, key: item.key, value: item.key}))

  const [selectColumn, setSelectColumn] = useState(defaultCheckedList)
  const [filterColumn, setFilterColumn] = useState([])


  useEffect(() => {
    const newColumn = columns.map((item) => ({
      ...item,
      hidden: !selectColumn.includes(item.key),
    }));
    setFilterColumn(newColumn)
  }, [selectColumn])  
    
  
  useEffect(() => {
    const fetchVideo = async() => {
      let filterData = {            
        state_type: selectVideoState
      };        
  
      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await video_id_find(filterData);       
        
        console.log("listResponse: ", listResponse)
  
        if (status === 200) {   
          //資料扁平化
          const result = [];
          for (const username in listResponse) {
              if (listResponse.hasOwnProperty(username)) {
                  const videos = listResponse[username];
                  Array.isArray(videos) && videos.map(item => {result.push({"用戶名稱": username, ...item})})                 
              }
          }
          setVideoListData(result) 
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
  
    fetchVideo()
  }, [isRefresh, selectVideoState])
 

  return (
    <>
      <div className='w-full'>
        <div className='flex flex-row py-2 w-full max-xl:flex-wrap max-xl:flex-col max-xl:items-center'>
          <Button type="primary" onClick={() => setShowVideoAdd(true)}>
            新增
          </Button>
              
          <TableFilter selectColumn={selectColumn} setSelectColumn={setSelectColumn} columnOptions={columnOptions}>
            <Select
              style={{ width: 120 }}            
              options={typeOptions}
              value={selectVideoState}
              onChange={(value) => {setSelectVideoState(value)}}
            />      
          </TableFilter> 

        </div>       
        <Table rowKey="影片id" style={{maxWidth: "100%"}} dataSource={videoListData} columns={filterColumn} scroll={{x: "max-content", y: tableScrollY, scrollToFirstRowOnChange: true}} />
        
      </div>
      {showVideoAdd && <VideoAdd showVideoAdd={showVideoAdd} setShowVideoAdd={setShowVideoAdd}/>}
      {showVideoEdit && <VideoEdit showVideoEdit={showVideoEdit} setShowVideoEdit={setShowVideoEdit} tableRowData={editVideoData}/>}
    </>
  );
};

export default VideoList;